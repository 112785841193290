import React from 'react';
import {isInPWA} from '@Utils/browser.util';
import ErrorBoundary from '../error-boundary';
import {PageLoadingBar} from '../page-loading-bar';

function HeaderApp() {
  return (
    <div>
      <ErrorBoundary>
        <React.StrictMode>{isInPWA() && <PageLoadingBar />}</React.StrictMode>
      </ErrorBoundary>
    </div>
  );
}

export default HeaderApp;
