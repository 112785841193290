import React, {useState} from 'react'
import './page-loading-bar.scss';
import LoadingBar from 'react-top-loading-bar'

const LOADING_BAR_COLOR = "#B2E4F6";
const STARTING_LOADING_VAL = 20;

const PageLoadingBar = () => {
    const [progress, setProgress] = useState(STARTING_LOADING_VAL);

    window.onbeforeunload = function () {
        setProgress(STARTING_LOADING_VAL);
    }

    window.onload = function () {
        setProgress(100);
    }

    return (<LoadingBar color={LOADING_BAR_COLOR} progress={progress} onLoaderFinished={() => setProgress(100)}/>);
}

export default PageLoadingBar
