import React from 'react';
import {HeaderApp} from '@Components/header-app';
import {createRoot} from 'react-dom/client';

const a = document.createElement('div');
a.setAttribute('id', 'pmw-header-app');
document.body.appendChild(a);

const root = createRoot(document.getElementById('pmw-header-app')!);
root.render(<HeaderApp />);
